import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { H4, InlineText, TextContainer, Paragraph, Ul, Tooltip } from "@siteimprove/fancylib";
import {
	Example,
	Knobs,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ToggleSwitch } from "./toggle-switch";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Toggle Switch",
	notepad: "https://hackmd.io/m76T5J9KQfOuJUVA_vsjTQ",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Toggle Switch"
				subTitle="Toggle Switch allows users to turn things on or off with an immediate effect."
			/>
			<ContentSection>
				<TextContainer article>
					<LabWarning />
					<ImportExample lab component={ToggleSwitch} />
					<Header.H2>Examples</Header.H2>
					<Paragraph>
						Think of <Code>ToggleSwitch</Code> as a physical switch that allows the user to turn
						things on or off, like a light switch. Use <Code>ToggleSwitch</Code> when the user has
						the freedom and control to change their settings as needed.
					</Paragraph>
					<Paragraph>
						A <Code>ToggleSwitch</Code> should always have a label and a default value, either{" "}
						<Code>true</Code> (on) or <Code>false</Code> (off).
					</Paragraph>
					<Example fn={BasicUsage} />
				</TextContainer>
				<TextContainer article>
					<Header.H3>Label Position</Header.H3>
					<Paragraph>
						Depending on layout constraints, labels can be positioned to the left or right of a{" "}
						<Code>ToggleSwitch</Code>. It is best to place the labels to the left of the{" "}
						<Code>ToggleSwitch</Code> so that users can more easily read the text and understand the
						meaning in the language written from left to right.
					</Paragraph>
					<Example fn={LabelPositionExample} />
				</TextContainer>
				<TextContainer article>
					<Header.H3>Custom Label</Header.H3>
					<Paragraph>
						Use a custom label to explain what happens when a <Code>ToggleSwitch</Code> is on or
						off. Explain how turning a switch on or off affects a user's decision.
					</Paragraph>
					<Example fn={CustomLabelExample} />
				</TextContainer>
				<TextContainer article>
					<Header.H2>Properties</Header.H2>
				</TextContainer>
				<Knobs
					component={ToggleSwitch}
					initialProps={({ setState }) => ({
						"aria-label": "an example of toggle-switch",
						label: "Label",
						value: false,
						onChange: (v) => setState({ value: v }),
					})}
				/>
				<TextContainer article>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Paragraph>
							Use <Code>ToggleSwitch</Code> when
						</Paragraph>
						<Ul
							items={[
								"applying a system state, or view not a contextual one.",
								"providing binary options, such as on/off or true/false.",
								"activating or deactivating something immediately.",
								"the user receives a clear visual cue between the two toggle states.",
							]}
						/>
						<Header.H4>Placement</Header.H4>
						<Paragraph>
							<Code>ToggleSwitch</Code> is typically used in the following place:
						</Paragraph>
						<Ul
							items={[
								<>
									<H4>System settings</H4>
									<Code>ToggleSwitch</Code> can be used to apply system settings or preferences. If
									you have multiple <Code>ToggleSwitch</Code>, each item in a set should be
									controlled independently.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								<>
									the user controls a single feature or minor setting. In this case, use a
									standalone{" "}
									<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink>{" "}
									instead.
								</>,
								<>
									the user can change at least two options that determine how or what content is
									displayed on the current page. In this case, use{" "}
									<GatsbyLink to="/lab/components/Actions and controls/Toggle Group">
										Toggle Group
									</GatsbyLink>
									.
								</>,
								<>
									designing a form. If the user is only allowed to select one option from a list of
									related items, then you should use{" "}
									<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink>.
								</>,
								<>the user must click a button for other changes to take effect.</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Paragraph>
							This component comes with built-in accessibility, no extra work required.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								<>
									The text of a label should not be changed depending on its state. Keep the label
									consistent.
								</>,
								<>
									Label the <Code>ToggleSwitch</Code> with one or two words, preferably nouns, that
									describe what the control does when the switch is on.
								</>,
								<>
									The label of the <Code>ToggleSwitch</Code> should be short and direct, e.g. "Email
									notifications". If in doubt, read the label aloud and add "on/off" at the end. If
									it does not make sense, rewrite the label.
								</>,
								<>
									Avoid redundant words that describe the switch itself. For example: "Allow", "Turn
									on" "Active" and "Enable".
								</>,
								<>Avoid confusing double negatives. For example: "Hide fixed issues".</>,
								<>Use sentence case for a label.</>,
								<>
									The toggle label should not be written with questions. For example: “Do you want
									to receive notifications?”
								</>,
							]}
						/>
					</InlineMessage>
				</TextContainer>
			</ContentSection>
		</>
	);
};

const BasicUsage = () => {
	const [checked, setChecked] = React.useState(false);
	return <ToggleSwitch label="Label" value={checked} onChange={setChecked} />;
};

const LabelPositionExample = () => {
	const [checked, setChecked] = React.useState(false);
	return (
		<>
			<ToggleSwitch
				label="Label in the left"
				labelPosition="left"
				value={checked}
				onChange={setChecked}
			/>
			<br />
			<ToggleSwitch
				label="Label in the right"
				labelPosition="right"
				value={checked}
				onChange={setChecked}
			/>
		</>
	);
};

const CustomLabelExample = () => {
	const [checked, setChecked] = React.useState(false);
	return (
		<ToggleSwitch
			label={
				<InlineText emphasis={checked ? "medium" : "normal"}>
					<Tooltip variant={{ type: "text" }} content="This is a tooltip">
						Label with tooltip
					</Tooltip>
				</InlineText>
			}
			value={checked}
			onChange={setChecked}
		/>
	);
};

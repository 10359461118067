import * as React from "react";
import {
	cn,
	DataObserveKey,
	FocusableComponent,
	InlineText,
	VisualComponent,
} from "@siteimprove/fancylib";
import * as scss from "./toggle-switch.scss";

export type ToggleSwitchProps = {
	/** Callback to switch the value */
	onChange: (v: boolean) => void;
	/** Determines the state of the toggle switch */
	value: boolean;
	/** Text to be displayed next to the toggle switch */
	label?: React.ReactNode;
	/** Text to describe the function of toggle switch */
	"aria-label"?: string;
	/** ID of an an element that describes what the toggle switch is for */
	"aria-describedby"?: string;
	/** Disabled state */
	disabled?: boolean;
	/** Should the label be displayed on the right or the left side of the toggle switch - default is right */
	labelPosition?: "left" | "right";
	/** Should the toggle element be full width? */
	fullWidth?: boolean;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function ToggleSwitch(props: ToggleSwitchProps): JSX.Element {
	const {
		label,
		labelPosition = "left",
		onChange,
		value: selected,
		disabled,
		className,
		style,
		tabIndex,
		onBlur,
		onFocus,
		onKeyDown,
		onMouseDown,
		onMouseEnter,
		onMouseLeave,
		fullWidth,
	} = props;

	return (
		<label
			aria-disabled={disabled}
			className={cn(scss.main, fullWidth && scss.fullWidth, className)}
			data-component="toggle-switch"
			data-observe-key={props["data-observe-key"]}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}
		>
			<button
				aria-describedby={props["aria-describedby"]}
				aria-label={props["aria-label"]}
				aria-pressed={selected}
				className={cn(scss.switchBtn, selected && scss.selected)}
				disabled={disabled}
				onClick={() => onChange(!selected)}
				tabIndex={tabIndex}
				type="button"
			/>
			{label && (
				<span className={cn(scss.label, scss[labelPosition])}>
					{typeof label === "string" ? (
						<InlineText emphasis={selected ? "medium" : "normal"}>{label}</InlineText>
					) : (
						label
					)}
				</span>
			)}
		</label>
	);
}
